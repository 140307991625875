<template>
<div class="item" id="publicity-item">
  <div class="index">{{  index+1 }}</div>
    <div class="user">
      <FileTableDialog
        title="报名文件"
        >
        <span>
          {{msg.user}}
        </span>
      </FileTableDialog>
    </div>
    <div >
      <!-- 报价 -->
      <input class="input print-content-line" style="width:50px !important;" v-model="form.quoteFirst"/>
      <a-input-number class="not-print-content" placeholder='请输入' v-model="form.quoteFirst"/>
    </div>
    <!-- 二次报价 -->
    <div>
      <input class="input print-content-line" style="width:50px !important;" v-model="form.quoteSec"/>
      <a-input-number class="not-print-content" placeholder='请输入' v-model="form.quoteSec"/>
    </div>
    <!-- 付款方式是否响应 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isPaymentResponse">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isPaymentResponse"/>
    </div>
    <!-- 资信承诺是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isQualification">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isQualification"/>
    </div>
    <!-- 供货范围是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isSupplyScope">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isSupplyScope"/>
    </div>
    <!-- 商务/技术要求是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isTecBusiness">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isTecBusiness"/>
    </div>

    <div style='flex: 2'>
      <a-textarea placeholder='请输入' 
      style="" 
      :auto-size="{ minRows: 1 }" 
      v-model="form.responseQuestion" 
      />
    </div>

    <div style='flex: 2;'>
        <a-checkbox :defaultChecked="defaultChecked" @change="onChange"></a-checkbox>
    </div>
</div>
</template>
<script>
import FileTableDialog from '@/views/bidding/components/file-table-dialog'

export default {
  components:{
    FileTableDialog
  },
  props:{
    msg:{
      type: Object,
      default: ()=>({})
    },
    index:{
      type: Number,
      default:0
    }
  },
  data () {
    return {
      form: { //评标意见表
        id: this.msg.id || '',
        userId: this.msg.userId || '',
        quoteFirst: this.msg.quoteFirst || null,
        quoteSec: this.msg.quoteSec || null,
        isPaymentResponse: this.msg.isPaymentResponse|| false,
        isQualification: this.msg.isQualification|| false,
        isSupplyScope: this.msg.isSupplyScope|| false,
        isTecBusiness: this.msg.isTecBusiness|| false, 

        responseQuestion: this.msg.responseQuestion|| "",//投标单位答疑回复
      },
      defaultChecked: this.msg.isChecked || false,
      
    }
  },
  mounted(){
    // 修改时，若选中，则回显数据
    if(this.defaultChecked){
      this.init()
    }
  },
  methods:{
    init(){
      this.$emit('getForm',{
        userId: this.msg.userId,
        user: this.msg.user,
        isChecked: true
      })
    },
    onChange(e){
      let value = e.target.checked;
      console.log(value)
      
      this.$emit('getForm',{
        userId: this.msg.userId,
        user: this.msg.user,
        isChecked: value
      })
      
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  & > div {
    flex: 1;
    padding: 12px 0;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .index{
    width: 44px; 
    flex: 0 0 auto;
  }
  // 公司单位
  .user{
    color: #1890ff;
    flex: 3;
    span{
      cursor: pointer;
    }
  }
  input{
    outline: none;
    border: none;
    text-align: center;
    width: 100px;
  }
  textarea{
    border: none;
    text-align: center;
    width: 100px;
  }

}

.print-content-line{
  display: none;
}

.item :first-child {
  & > div {
    background: #fff;
  }
}

</style>
<style lang="less">
#publicity-item{
  .ant-input-number{
    border: none;
    width: 70px;
    .ant-input-number-input{
      text-align: center;
    }
  }
  
  .ant-checkbox{
    .ant-checkbox-inner{
      border-radius: 50%;
    }
  }
  .ant-checkbox-checked::after{
    border: none;
  }
}
</style>