<template>
  <div>
    <Pane />

    <div class="container">
      <div id="printContent">
        <div class="header">
          <div class="title">评标结果汇总</div>
        </div>

        <div class="row">
          <div class="project-name">
            <span> 项目名称：</span>
            <span> {{this.detail.projectName}} </span>
          </div>

          <div class="print-download not-print-content">
            <span @click="exportFile">
              <a-icon type="table"
                      style="margin-right:5px;color:#1890ff;" />导出表格
            </span>
            <span v-print="printObj">
              <a-icon type="printer"
                      style="margin-right:5px;color:#564fc1;" />打印页面
            </span>
          </div>
        </div>

        <Table />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/table";
import { fetchDetail } from "@/api/bidding";
import { getJuryList, downloadSummary } from "@/api/bidding/evaluation";

import { saveAs } from "file-saver";

export default {
  components: {
    Table,
  },
  data() {
    return {
      itemId: "", // 标段id
      detail: {},

      printObj: {
        id: "printContent",
      },
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      this.itemId = id;
      this.getDetail(id);
      // 获取评委列表-包括纪检审计代表、评标委员
      this.getJuryList(id);
    }
  },

  methods: {
    getDetail(id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.detail = res;
      });
    },

    getJuryList(id) {
      getJuryList(id).then((res) => {
        this.detail.registerList = res.registerList.map((item) => {
          return {
            id: item.id, // 报名id
            userId: item.userId, //报名单位id
            user: item.user, // 报名单位名称
            registerFile: {
              filesBidBondCert: item.filesBidBondCertList,
              filesBusiness: item.filesBusinessList,
              filesOther: item.filesOtherList,
              filesTech: item.filesTechList,
            },
          };
        });
      });
    },

    exportFile() {
      downloadSummary(this.itemId).then((blob) => {
        saveAs(blob, "评标意见表.doc");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 32px 116px;
}

.header {
  text-align: center;
  position: relative;
  margin-bottom: 32px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }
  .extra {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  .project-name {
    font-weight: bold;
    font-size: 16px;

    & > span:last-child {
      color: #1890ff;
    }
  }
  .print-download {
    span:first-child {
      margin-right: 20px;
    }
    span {
      cursor: pointer;
    }
  }
}
</style>