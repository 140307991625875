<template>
  <div class="wrapper">
    <div class='description'>
      <div class='label'>标段名称</div>
      <div class='value'>{{detail.name}}</div>
      <div class='label'>开标地点</div>
      <div class='value'>{{detail.bidAddress}}</div>
      <div class='label'>招标方式</div>
      <div class='value1'>
        <DataDictFinder
        dictType="bid_method"
        :dictValue="detail.bidMethod"
          />
      </div>
      <div class='label'>开标日期</div>
      <div class='value2'>{{detail.bidDate}}</div>
    </div>

    <div class='list'>
      <div class='item'>
        <div style='width:44px; flex: none'>序号</div>
        <div style='flex: 3'>
          报名单位
        </div>

        <div class="price">
          <div>报价/万元</div>
        </div>

        <div class="price">
          <div>二次报价/万元(若有)</div>
        </div>

        <div class="switch">
          <div>付款方式是否响应</div>
        </div>

        <div class="switch">
          <div>资信承诺是否符合</div>
        </div>

        <div class="switch">
          <div>供货范围是否符合</div>
        </div>

        <div class="switch">
          <div>商务/技术要求是否符合</div>
        </div>

        <div style='flex: 2'>
          <div style="width:100px;">
            投标单位答疑回复
          </div>
        </div>

        <div style='flex: 2'>
          <div>
            请选择中标公司
          </div>
        </div>
      </div>

      <div v-for="(item, index) in formList" :key="item.id">
        <TableItem :msg="item" :index="index" @getForm="getForm"/>
      </div>
    </div>

      <div class='textarea'>
        <div>
          <div class="opinion">
            <div>
              <div style="width: 90px;">
                评标意见汇总：
              </div>
            </div> 
            <div>
              {{ juryLeaderRemarks }}
            </div>
          </div>
          <div class="sign">
            评标委员签字：
          </div>
        </div>

        <div>
          纪检审计代表签字：
        </div>
      </div>

      <div class="description">
        <div class='label center'>中标通知书</div>
        <div class='value'>
          <FileItem @addFile="getFile" :name="'winbidFiles'" :msg="selectFile['winbidFiles']"/>
        </div>
        <div class='label center'>经决会依据</div>
        <div class='value'>
          <FileItem @addFile="getFile" :name="'decisionFiles'" :msg="selectFile['decisionFiles']"/>
        </div>
      </div>

      <div class="btn-center not-print-content center" v-if="isBidManager">
        <a-space>
          <a-button @click="$close($route.path)">取消</a-button>
          <a-button type="primary" @click="submit" :loading="saveLoading"
            >发送</a-button
          >
        </a-space>
      </div>
  </div>
</template>

<script>
import TableItem from './table-item.vue'
import FileItem from "./FileItem";

import { fetchDetail, sendWin } from "@/api/bidding";
import { getJuryList } from "@/api/bidding/evaluation";
import { mapState, mapGetters } from 'vuex'
export default {
  components:{
    TableItem,
    FileItem
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isBidManager"]),
  },
  data() {
    return {
      detail: {},
      
      formList: [], // 报名公司信息及评价id信息
      itemId:'',// 标段id
      chooseList: [], // 选择记录
      juryLeaderRemarks: "",//评标意见

      selectFile:{},//选中的文件

      saveLoading: false,
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    this.itemId = id;
    if (id) {
      this.getDetail(id);
      this.getRegisterInfo(id) // 获取报名单位的信息及自身的评标id
    }
  },

  methods: {
    getDetail(id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.detail = res;
      });
    },
    getRegisterInfo(id){
      getJuryList(id).then((res) => {
        console.log(res)

        // 获取报名单位的信息
        if(!Array.isArray(res.registerList)){
          return;
        }
        
        this.formList = res.registerList;
        
        // 回显中标公司
        if(this.formList.find(item=>item.userId === res.winbidUser) !== undefined){
          this.formList.find(item=>item.userId === res.winbidUser).isChecked = true;
        }
        this.selectFile['winbidFiles'] = res.windBidFileList;
        this.selectFile['decisionFiles'] = res.decisionFileList;
        console.log(this.formList)
        console.log(this.selectFile)
        
        this.juryLeaderRemarks = res.juryLeaderRemarks;
      });
    },

    getForm(value){
      console.log('getForm',value)
      // 如有，替换，没有，添加
      let index = this.chooseList.findIndex(item=>{
        return item.userId == value.userId
      })
      
      if(index > -1){
        this.chooseList.forEach(item=>{
         if(item.userId == value.userId){
          item.isChecked = value.isChecked
         }
        })
      }else{
        this.chooseList.push(value)
      }

      console.log(this.chooseList)//获取了所有框的选择状态
      
    },

    getFile(val){
      // 根据name，替换选择的文件
      this.selectFile[val.name] = val.fileList
      console.log(this.selectFile)
    },

    submit() {
      // 校验
      // 过滤选中的公司
      let target = this.chooseList.filter(item => item.isChecked);
      console.log(target);

      if(!target.length){
        this.$message.error("请选择中标公司！");
        return;
      }

      if(target.length > 1){
        this.$message.error("中标公司只能有一个！");
        return;
      }
      console.log(this.selectFile)
      console.log(this.selectFile?.['winbidFiles']?.length)
      
      if(!this.selectFile?.['winbidFiles'] || !this.selectFile?.['winbidFiles']?.length){
        this.$message.error("请上传中标通知书！");
        return;
      }
      if(this.selectFile['winbidFiles'].length > 1){
        this.$message.error("中标通知书只能上传一份！");
        return;
      }
      if(this.selectFile?.['decisionFiles']?.length > 1){
        this.$message.error("经决会依据只能上传一份！");
        return;
      }
      
      // 根据接口参数修改
      let params = {};
      if(this.selectFile ?. ['decisionFiles'] ?. length > 0){
        params = {
          id: this.itemId,
          winbidUser: target[0].userId,
          winbidName: target[0].user,
          winbidFiles: JSON.stringify(this.selectFile['winbidFiles']),
          decisionFiles: JSON.stringify(this.selectFile['decisionFiles'])
        }
      }else{
        params = {
          id: this.itemId,
          winbidUser: target[0].userId,
          winbidName: target[0].user,
          winbidFiles: JSON.stringify(this.selectFile['winbidFiles']),
        }
      }
      console.log(params);

      let that = this;
      this.$confirm({
        title: `确认向${params.winbidName}发送中标通知？`,
        onOk() {
          console.log(that);
          sendWin(params).then(() => {
            that.getDetail(that.itemId);
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  .title {
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    padding: 12px 0
  }
}

.description {
  border: 1px solid #e8e8e8;
  border-bottom-width: 0;
  border-right-width: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .label {
    background: #fafafa;
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    text-align:center;
  }
  .value {
    padding: 12px;
    grid-column-start: 2;
    grid-column-end: 5;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;
  }
  .value1 {
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;

  }
  .value2 {
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;

  }
}

.list {
    border: 1px solid #e8e8e8;
    border-top-width: 0;
    border-bottom-width: 0;
    border-right-width: 0;
  .item {
    display: flex;
    & > div {
      flex: 1;
      padding: 12px 0;
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }
    .price{
      div{
        width: 70px;
      }
    }
    .switch{
      div{
        width: 44px;
      }
    }
  }
  .item:first-child {
    & > div {
      background: #fafafa;
    }
  }
}

input{
  outline: none;
  border: none;
}

.textarea {
  border: 1px solid #e8e8e8;
  border-top-width: 0;
  display: flex;
  align-items: baseline;
  padding: 12px;
  .opinion{
    display:flex; 
    align-items: flex-start;
    margin-right: 20px;
  }
  .sign{
    margin:20px 0;
  }
  > div {
    flex: 1;
  }
  > div:first-child {
    flex: 2;
  }
}

.btn-center {
  margin-top: 80px;
}
</style>
