<template>
<div class="file">
  <div class="link-list">
    <a-checkbox-group
      :value="selectedFileList"
      @change="onSelectFile"
    >
      <div class="link" v-for="item in fileList" :key="item.id">
        <a-checkbox :value="item.id">
          <a target="_blank" download :href="item.completePath">{{
            item.name
          }}</a>
        </a-checkbox>
      </div>
    </a-checkbox-group>

    <div class="control-bar">
      <FileUpload @uploaded="uploaded">
        <div class="control">
          <a-icon type="upload" />
        </div>
      </FileUpload>
      <div class="control" @click="deleteFile">
        <a-icon type="delete" />
      </div>
    </div>
  </div>
    
</div>
</template>
<script>
import FileUpload from "@/components/file-upload";
export default {
  props:{
    name:{
      type:String,
      default:''
    },
    msg: {
      type: Array,
      default: () => []
    }
  },
  components:{
    FileUpload

  },
  data () {
    return {
      fileList: [],
      selectedFileList: [],
      
    }
  },
  watch: {
    msg (val){
      this.fileList = val;
      this.$emit('addFile', {
        name:this.name,
        fileList:this.fileList
      })
    }
  },
  mounted () {
    this.fileList = this.msg || [];
  },
  methods:{
    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
      this.$emit('addFile',{
        name:this.name,
        fileList:this.fileList
      })
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
      this.$emit('addFile',{
        name:this.name,
        fileList:this.fileList
      })
    },
  }
}
</script>
<style lang="less" scoped>
.link-list{
  border: none;
}
</style>
